import React, { useEffect, useState } from 'react';
import Header from './Header';
import HeroSection from './HeroSection';
import Footer from './Footer';
import Trust from './Trust';
import User from './User';
import SplashScreen from './Splash'; // Your loader component
import logo from '../Images/gno-wallet.jpeg'; // Import the logo image

const Home = () => {
  const [isPWA, setIsPWA] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isIOSOrWindows, setIsIOSOrWindows] = useState(false); // State to check if iOS or Windows

  useEffect(() => {
    // Detect if the app is running as a PWA
    if (
      window.matchMedia('(display-mode: standalone)').matches ||
      window.navigator.standalone === true // For iOS
    ) {
      setIsPWA(true); // Set isPWA to true if it’s a PWA
    }

    // Check if the device is iOS or Windows
    const isIOSOrWindowsDevice = /iPhone|iPad|iPod/i.test(navigator.userAgent) || /Windows/i.test(navigator.userAgent);
    setIsIOSOrWindows(isIOSOrWindowsDevice);

    // Simulate loading time (e.g., 2 seconds)
    const timer = setTimeout(() => {
      setLoading(false); // Hide splash screen after 2 seconds
    }, 2000);

    return () => clearTimeout(timer); // Cleanup timer on component unmount
  }, []);

  return (
    <div>
      {isPWA && isIOSOrWindows && loading ? ( // Show splash screen only for iOS and Windows
        <SplashScreen
          appName="REIVUN"
          logoSrc={logo} // Directly use the imported logo
        />
      ) : (
        <>
          <Header />
          <HeroSection />
          <User />
          <Trust />
          <Footer />
        </>
      )}
    </div>
  );
};

export default Home;
